import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Stack, Grid, Button, FormControl, InputLabel, Select, MenuItem, Tooltip, Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Menu, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, NavLink } from 'react-router-dom';
import Notify from '../../helpers/notifyConfig';
import axios from '../../../api/axios';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FormikForm } from '../../../pages/ContractSigning/FormikForm';
import { format } from 'date-fns';
import WarningIcon from '@mui/icons-material/Warning';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// import theme from '../../../theme';

const formattedDate = (myDate) => {
  return format(myDate, 'dd MMM yyyy');
}

const dateDiff = (myDate) => {
  const today = new Date();
  const targetDate = new Date(myDate);
  const differenceInMilliseconds = targetDate - today;
  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  var ans = "";
  if (differenceInDays <= 60) ans = "error";
  else if (differenceInDays <= 180) ans = "warning";
  else ans = "success";

  return ans;
}

const dateIcons = (date) => {
  if (date == "error") return <WarningIcon />;
  else if (date == "warning") return <NotificationImportantIcon />;
  return <CheckCircleIcon />;
}

const defaultData = {
  "company_id": "",
  "product_name": "",
  "company_name": "",
  "duration": "",
  "start_date": "",
  "end_date": "",
  "price": 0,
  "quantity": 0,
  "certificates_details": [{
    "certificate_name": "",
    "expiry_date": "",
  }],
}

const color_cont = {
  'active': "#6895D2",
  'inactive': "red",
}

export function SingleTable({ titles, accountInfo, account = false, setLoading, setEditAccount, editOpen, setEditOpen, type = "", setRefresh, editData }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(type == "edit" ? editData : defaultData);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // conso.le.log(editData);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    if (type == "edit") setEditOpen(false);
  };

  const submitAddForm = (type) => {
    let hasWarning = false;

    if (data.product_name === "") {
      Notify.warning("Product Name field cannot be empty!");
      hasWarning = true;
    }
    else if (data.duration === "") {
      Notify.warning("Duration field cannot be empty!");
      hasWarning = true;
    }
    else {
      // 1. Adding New Product in Contract Signing
      if (type == "add") {
        const addProduct = async () => {
          await axios.post("/dashboard/contract/addproduct",
            JSON.stringify(data),
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                mode: 'no-cors',
                'Authorization': `Bearer ` + localStorage.getItem('access')
              }
            }).then((response) => {
              console.log(response);
              setOpen(false);
              setLoading(prev => !prev);
              setData(defaultData);
              Notify.success("Product Added Successfully!")
            }).catch((error) => {
              console.log(error.response);
              Notify.error("Error Occurred. Try Again!");
            })
        }
        addProduct();
      }
      else {
        const result = {
          "_id": data._id,
          "product_name": data.product_name,
          "certificates_details": data.certificates_details,
          "duration": data.duration,
          "start_date": data.start_date,
          "end_date": data.end_date,
          "price": data.price,
          "quantity": data.quantity,
        }
        const editProduct = async () => {
          await axios.post("/dashboard/contract/editproduct",
            JSON.stringify(result),
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                mode: 'no-cors',
                'Authorization': `Bearer ` + localStorage.getItem('access')
              }
            }).then((response) => {
              console.log(response);
              setRefresh(prev => !prev);
              setEditOpen(false);
              Notify.success("Product Edited Successfully!")
            }).catch((error) => {
              console.log(error.response);
              Notify.error("Error Occurred. Try Again!");
            })
        }
        editProduct();
      }
    }
  }

  const handleClickOpen = (id, name) => {
    setOpen(true);
    setAnchorEl(null);
    setData({
      "company_id": id,
      "product_name": "",
      "company_name": name,
      "duration": "",
      "start_date": "",
      "end_date": "",
      "price": 0,
      "quantity": 0,
      "certificates_details": [{
        "certificate_name": "",
        "expiry_date": "",
      }],
    });
  };


  const headStyle = {
    backgroundColor: "primary.light",
  }

  // Delete Account
  const deleteAction = (id, type) => {
    setAnchorEl(null);
    const deleteSale = async () => {
      await axios.delete(`/dashboard/contract/deleteaccount`,
        {
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('access')
          },
          data: {
            "_id": id
          }
        }).then((response) => {
          type == "account" ? Notify.success('Account Deleted Successfully!') : Notify.success('Product Deleted Successfully!');
          setLoading(prev => !prev)
        }).catch((error) => {
          type == "account" ? Notify.error('Account Deletion Failed. Try Again!') : Notify.error('Product Deletion Failed. Try Again!');
        })
    }
    deleteSale();
  }

  // Edit Account
  const editAction = (id, type) => {
    setAnchorEl(null);
    const editSale = async () => {
      await axios.put(`/dashboard/contract/editaccount`,
        JSON.stringify({ _id: id }),
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            withCredentials: true,
            mode: 'no-cors',
            'Authorization': `Bearer ` + localStorage.getItem('access')
          }
        }).then((response) => {
          console.log(response);
          setEditAccount(response?.data);
        }).catch((error) => {
          Notify.error("Try Again!")
        })
    }
    editSale();
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "75vh", borderRadius: "2px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ ...headStyle }}>
          <TableRow>
            {titles.map((val, index) =>
              <TableCell align={val == 'Action' || val == 'Expiry Date' ? 'center' : 'left'} sx={{ fontWeight: "600", color: "primary.white", borderRight: '1px solid white' }} key={index}>{val}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {accountInfo.length == 0 ? <TableRow sx={{ textAlign: 'center' }}><Typography p={2} variant='h6'>No Data Found!</Typography></TableRow> :
            accountInfo.map((row, index) => (
              <>
                <TableRow
                  key={index}
                  sx={{ borderBottom: '1px solid #ccc' }}
                >
                  {/* <TableCell align="left"><Typography pl={2}>{row._id?row._id:row.certificate_name}</Typography></TableCell> */}
                  <TableCell align="left" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                    {account ? <Typography fontWeight={'600'}>{row.company_name}</Typography> :
                      <Typography color="#1B2C58">{row.certificate_name}</Typography>}
                  </TableCell>

                  {account ? <TableCell align="left" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                    <Typography>{row.status.toUpperCase()}</Typography>
                  </TableCell> : <></>}

                  {/* {account ? <TableCell align="left" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                    <Typography>{row.company_address}</Typography>
                  </TableCell> : <></>} */}

                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {/* <Box py={1} display={'flex'}><Button onClick={() => handleClickOpen(row._id, row.company_name)} sx={{ paddingLeft: '0' }}><AddIcon sx={{ color: "green" }} /><Typography sx={{ color: "green" }}>Add New</Typography></Button></Box> */}
                      {row.products.map((val, index) => {
                        const product_route = `/contractsigning/${val.product_id}`;
                        return (
                          // <Box sx={index!=([row.products].length-1)?{borderBottom:"1px solid #ccc"}:""} key={index} py={1} display={'flex'}><Link style={{ textDecoration: "none", color: "#0C359E" }} to={product_route}><Typography>{val.product_name}</Typography></Link></Box>
                          <Box key={index} py={1} display={'flex'}><Link style={{ textDecoration: "none", color: "#0C359E" }} to={product_route}><Typography>{val.product_name}</Typography></Link></Box>
                        )
                      }
                      )}
                    </TableCell>
                    :
                    <TableCell sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }} align="center"><Typography>{new Date(row.expiry_date).toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }).replace(',', '')}</Typography></TableCell>}

                  {/* REVENUE */}
                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {/* {const sd =""} */}
                      {row.products.map((val, index) => {
                        const product_route = `/contractsigning/${val.product_id}`;
                        return (
                          <Box key={index} py={1} display={'flex'}><Typography color='green' fontWeight='600'>${val.price}</Typography></Box>
                        )
                      }
                      )}
                    </TableCell>
                    : <></>}

                  {/* END DATE */}
                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {row.products.map((val, index) => {
                        return (
                          <Box key={index} py={1} display={'flex'}><Chip color={dateDiff(val.end_date)} icon={dateIcons(dateDiff(val.end_date))} label={formattedDate(val.end_date)} /></Box>
                        )
                      }
                      )}
                    </TableCell>
                    : <></>}

                  <TableCell sx={{ borderBottom: '1px solid #ccc' }} align="center">
                    {account ?
                      <>
                        <IconButton
                          id="basic-button"
                          aria-controls={openAction ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openAction ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openAction}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => handleClickOpen(row._id, row.company_name)}><AddIcon sx={{ color: "green", fontSize: '22px' }} />&nbsp;&nbsp;Add Product</MenuItem>
                          <MenuItem id={"edit" + row._id} onClick={(e) => editAction(row._id, account ? "account" : "product")}><EditIcon sx={{ fontSize: '20px' }} />&nbsp;&nbsp;Edit Account</MenuItem>
                          <MenuItem id={"del" + row._id} onClick={(e) => deleteAction(row._id, account ? "account" : "product")}><DeleteIcon sx={{ color: "#D24545", fontSize: '20px' }} />&nbsp;&nbsp;Delete Account</MenuItem>
                        </Menu>
                        {/* <Tooltip title="Add Product"><Button onClick={() => handleClickOpen(row._id, row.company_name)}><AddIcon sx={{ color: "green" }} /></Button></Tooltip>
                  <Tooltip title="Edit Account"><Button id={"edit" + row._id} onClick={(e) => editAction(row._id, account ? "account" : "product")}><EditIcon /></Button></Tooltip>&nbsp;
                  <Tooltip title="Delete Account"><Button id={"del" + row._id} onClick={(e) => deleteAction(row._id, account ? "account" : "product")}><DeleteIcon sx={{color: "#D24545" }} /></Button></Tooltip> */}
                      </>
                      : <></>}
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
      </Table>

      {/* To add a new product for any account */}
      <Dialog
        open={editOpen ? editOpen : open}
        onClose={handleClose}
        aria-labelledby="edit-apartment"
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Product (for {data.company_name})</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
          <FormikForm data={data} setData={setData} type="product" />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          {
            type == "edit"
              ? <Button variant='contained' onClick={() => submitAddForm("edit")} color="primary">Save</Button>
              : <Button variant='contained' onClick={() => submitAddForm("add")} color="primary">Add</Button>
          }
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}