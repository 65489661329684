import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Menu, MenuItem, Pagination, PaginationItem, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { advancedDate, postData } from '../../pages/Events'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Notify from '../../features/helpers/notifyConfig';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState, React } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import SearchIcon from '@mui/icons-material/Search';
import { NodataFound } from '../../pages/NodataFound';
import ClearIcon from '@mui/icons-material/Clear';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Cancel, CheckCircle, CheckCircleOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import axios, { CryptoJSsecretKey } from '../../api/axios';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { CredentialForm } from './CredentialForm';
import { currentUser, showRoute, specificpermCheck } from '../../Router';
import CryptoJS from 'crypto-js';
import { isSuperAdmin } from '../auth/userAuth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const isValidUrl = urlString => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}

const headerStyle = {
    backgroundColor: '#1b2c58',
    color: 'white',
    padding: '8px 10px',
    fontSize: '17px',
    fontFamily: 'Segoe UI',
    fontWeight: '700',
    marginBottom: '10px'
}

export const InputHeight = {
    '.MuiInputBase-root': {
        background: '#f3f3f3 !important',
        height: '35px !important',
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
    }
}

const Button2Style = {
    '&.MuiButtonBase-root': {
        height: '35px !important',
        padding: '0px 35px',
        background: '#198f51'
    }
}

const ButtonStyle = {
    position: 'relative',
    '&.MuiButtonBase-root': {
        height: '35px !important',
        padding: '0px 20px',
        background: '#CCDAFF',
        border: '1px solid #4769c3',
        color: '#4769c3',
    }
}

const ButtonRedStyle = {
    '&.MuiButtonBase-root': {
        height: '35px !important',
        padding: '0px 20px',
        background: '#FFDADA',
        border: '1px solid #f04949',
        color: '#f04949',
    }
}

const labels = {
    'name': 'Company',
    'username': 'Username',
    'password': 'Password',
    'last_modified': [
        {
            'last_modified_on': ''
        }
    ]
}

const simpleStyle = {
    backgroundColor: '#ededed',
    padding: '6px 10px',
    fontSize: '15px',
    fontFamily: 'Segoe UI',
    marginBottom: '4px'
}

const CopytoClipboard = (text) => {
    navigator.clipboard.writeText(text);
    Notify.success('Text copied to clipboard');
}

export const IconStyle = {
    fontSize: '13px',
    color: 'gray',
    cursor: 'pointer',
    ':hover': {
        color: '#78A1DE',
        transform: 'scale(1.2)'
    }
}

export const CredentialsView = ({ data, setLoading, credReview, isCredReview }) => {
    const [credentials, setCredentials] = useState(data.message);
    const [searched, setSrch] = useState('');
    const [open, setOpen] = useState(false);
    const approvers = data.approvers;
    const [anchorElMap, setAnchorElMap] = useState({});
    const [addForm, setAddForm] = useState(false);
    const [editForm, setEdit] = useState(false);
    const [showPassword, setShowPassword] = useState(null);
    const [showRequest, setshowRequest] = useState(false);
    const [dataPage, setDataPage] = useState(0);
    const handleClickShowPassword = (id) => {
        showPassword == id
            ? setShowPassword(null)
            : setShowPassword(id);
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [addData, setAddData] = useState({
        "url": "",
        "name": "",
        "username": "",
        "password": "",
        "notes": ""
    });
    const { pCredentials } = currentUser();

    const onDataPageChange = (event, page) => {
        setDataPage(page - 1);
    }

    const viewRequest = () => {
        const filteredData = data.message.filter(item =>
            item.showApprovalButton == true
        );
        setshowRequest(true);
        setCredentials(filteredData);
    }

    const closeRequest = () => {
        setshowRequest(false);
        setCredentials(data.message);
    }

    useEffect(() => {
        setshowRequest(false);
        setCredentials(data.message);
    }, [data])

    //1. Adding Credential
    const submitAddForm = () => {
        if (addData.name == "") Notify.warning("Please enter the name");
        else if (addData.url == "") Notify.warning("Please enter the url");
        else if (!isValidUrl(addData.url)) Notify.warning("Please enter a valid url or full address!");
        else if (addData.username == "") Notify.warning("Please enter the username");
        else if (addData.password == "") Notify.warning("Please enter the password");
        else if (editForm && Object.keys(addData.approver).length == 0 && !isSuperAdmin(true, false)) Notify.warning("Please select a valid approver");
        else {
            const finData = {
                "url": addData.url,
                "name": addData.name,
                "username": addData.username,
                "password": CryptoJS.AES.encrypt(addData.password, CryptoJSsecretKey).toString(),
                "notes": addData.notes
            }
            if (editForm) {
                finData['_id'] = addData._id;
                finData['approver'] = addData.approver;
            }

            const api = editForm ? "/dashboard/loginCredentials/edit" : "/dashboard/loginCredentials/add"
            const method = editForm ? axios.put : axios.post;
            const AddRequest = async () => {
                await method(api,
                    JSON.stringify(finData),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            withCredentials: true,
                            mode: 'no-cors',
                            'Authorization': `Bearer ` + localStorage.getItem('access')
                        }
                    }).then((response) => {
                        Notify.success(editForm ? (isSuperAdmin(true, false) ? "Credentials updated successfully" : "Update Request has been sent to the approver") : "Credentials added successfully");
                        setAddForm(false);
                        setOpen(false);
                        setLoading(true);
                    }).catch((error) => {
                        Notify.error("Some error occurred. Try Again!");
                        setAddForm(false);
                    })
            }

            setAddForm(true);
            AddRequest();
        }
    }

    const handleClick = (keey) => {
        const prev = anchorElMap[keey];
        setAnchorElMap({});

        if (!prev) {
            setAnchorElMap(prevMap => ({
                ...prevMap,
                [keey]: true,
            }));
        }
    };

    const openAdd = () => {
        setEdit(false);
        setAddData({
            "url": "",
            "name": "",
            "username": "",
            "password": "",
            "notes": ""
        })
        setOpen(true);
    }

    const openEdit = (data) => {
        setEdit(true);
        setAddData({
            "_id": data._id,
            "url": data.url,
            "name": data.name,
            "username": data.username,
            "approver": {},
            "password": CryptoJS.AES.decrypt(data.password, CryptoJSsecretKey).toString(CryptoJS.enc.Utf8),
            "notes": data.notes
        });
        setAnchorElMap({});
        setOpen(true);
    }

    const maskPassword = (password) => {
        return '•'.repeat(password.length);
    };

    const openDelete = async (id) => {
        console.log(id);
        await axios.delete(`/dashboard/loginCredentials/delete`,
            {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                },
                data: {
                    "_id": id
                }
            }).then((response) => {
                setLoading(true);
                Notify.success('Credential Deleted Successfully!');
            }).catch((error) => {
                Notify.error('Some Error Occurred. Try Again!');
            })
    }

    const manageRequest = async (id, res) => {
        await axios.post("/dashboard/loginCredentials/edit/approved",
            JSON.stringify({
                "_id": id,
                "status": res
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setLoading(true);
                Notify.success(`Request ${res ? "Approved" : "Rejected"} Successfully!`);
                isCredReview(prev => prev - 1);
            }).catch((error) => {
                Notify.error("Some error occurred. Try Again!");
            })
    }

    const openRenew = async (id) => {
        await axios.post("/dashboard/loginCredentials/renew",
            JSON.stringify({
                "_id": id
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setLoading(true);
                Notify.success('Request Mail has been sent successfully!');
            }).catch((error) => {
                Notify.error("Some error occurred. Try Again!");
            })
    }

    const onClose = () => {
        setOpen(false);
    }

    const CredView = ({ logo, value, action = '', keey, hdr = '' }) => {
        const mod_len = value['last_modified'].length - 1;
        const hdrCheck = (a, b) => {
            return hdr != '' ? a : b;
        }
        // console.log(keey);
        const Stacker = ({ value, type = '', width = '', id }) => {
            if (type == 'pass') value = CryptoJS.AES.decrypt(value, CryptoJSsecretKey).toString(CryptoJS.enc.Utf8);
            return (
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography width={width} textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' fontSize='15px' fontWeight='400' fontFamily='Segoe UI'>{(type == 'pass' && showPassword != id) ? maskPassword(value) : value}</Typography>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        {
                            type == 'pass'
                                ? showPassword == id ? <VisibilityOff sx={IconStyle} onClick={() => handleClickShowPassword(id)} /> : <Visibility sx={IconStyle} onClick={() => handleClickShowPassword(id)} />
                                : <></>
                        }
                        <ContentCopyIcon onClick={() => CopytoClipboard(value)} sx={IconStyle} />
                    </Stack>
                </Stack>
            )
        }

        return (
            <Grid
                borderRadius='5px'
                sx={hdr != '' ? headerStyle : simpleStyle}
                container key={keey} alignItems='center'>
                <Grid item xs={3}>
                    {hdrCheck(value.name, <NavLink style={{ textDecoration: 'none' }} to={isValidUrl(value.url) ? value.url : ('https://' + value.url)} target='_blank' rel="noopener noreferrer">
                        <Stack
                            sx={{
                                ':hover': {
                                    '.newTabIcon': {
                                        transform: 'scale(1.2)',
                                        color: '#0d3797'
                                    },
                                    '.typo': {
                                        color: '#0d3797'
                                    }
                                }
                            }}
                            direction='row' spacing={1} alignItems='center'>
                            <Typography maxWidth='80%' className='typo' fontSize='15px' fontWeight='400' fontFamily='Segoe UI' color='#1a4fc6' sx={{ wordBreak: 'break-all' }}>{value.name}</Typography>
                            {hdr == '' ? <OpenInNewIcon className='newTabIcon'
                                sx={{
                                    fontSize: '13px',
                                    color: '#1a4fc6',
                                    cursor: 'pointer'
                                }} /> : <></>}
                        </Stack>
                    </NavLink>)}
                </Grid>

                <Grid item xs={3}>
                    {hdrCheck(value.username, <Stacker width='72%' value={value.username} />)}
                </Grid>
                <Grid item xs={3}>
                    {hdrCheck(value.password, <Stacker id={value._id} width='55%' type='pass' value={value.password} />)}
                </Grid>
                {showRequest
                    ? <></>
                    : <Grid item xs={2}>
                        {hdrCheck('Last Updated', <Stack direction='row' spacing={1} alignItems='center'>
                            <Typography fontSize='15px' fontWeight='400' fontFamily='Segoe UI'>
                                {advancedDate(value['last_modified'][mod_len]['last_modified_on'])}
                            </Typography>
                            {/* <Tooltip arrow title='Someone requested to renew the credential details'>
                            <ErrorIcon color='warning' sx={{ fontSize: '18px' }} />
                        </Tooltip> */}
                        </Stack>)}
                    </Grid>
                }
                <Grid item xs={showRequest ? 3 : 1}>
                    {hdrCheck('Action',
                        !showRequest
                            ? <Box>
                                <IconButton
                                    id={`basic-button-${keey}`}
                                    onClick={() => handleClick(keey)}
                                    sx={{ position: 'relative', height: '25px' }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <ClickAwayListener onClickAway={() => setAnchorElMap({})}>
                                    <Box
                                        id={`basic-menu-${keey}`}
                                        display={anchorElMap[keey] ? 'block' : 'none'}
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            boxShadow: '1px 4px 12px #c0c0c0',
                                            position: 'absolute',
                                            right: '25px',
                                            zIndex: '99'
                                        }}
                                    >
                                        {/* <Box py={0.8} pr={2.5} pl={1} sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#f0f0f0e3' } }} onClick={() => openEdit(value)}><Typography variant='body2'>Details</Typography></Box> */}
                                        {showRoute((specificpermCheck(pCredentials, ['Edit Login Credentials'])), <Box disabled={value['requestedApproval']} py={0.8} pr={2.5} pl={1} sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#f0f0f0e3' } }} onClick={() => value['requestedApproval'] ? "" : openEdit(value)}><Typography variant='body2'>Edit</Typography></Box>, <></>)}
                                        {showRoute((specificpermCheck(pCredentials, ['Delete Login Credentials'])), <Box py={0.8} pr={2.5} pl={1} sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#f0f0f0e3' } }} onClick={() => openDelete(value._id)}><Typography variant='body2'>Delete</Typography></Box>, <></>)}
                                        {showRoute((specificpermCheck(pCredentials, ['Renew Login Credentials'])), <Box py={0.8} pr={2.5} pl={1} sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#f0f0f0e3' } }} onClick={() => value['requestedApproval'] ? "" : openRenew(value._id)}><Typography variant='body2'>Ask for Renewal</Typography></Box>, <></>)}
                                    </Box>
                                </ClickAwayListener>
                            </Box>
                            :
                            <Stack direction='row' spacing={2}>
                                <Button variant='contained' size='sm' color='error' onClick={() => manageRequest(value._id, false)}>Reject &nbsp;<Cancel sx={{ fontSize: '18px' }} /></Button>
                                <Button variant='contained' size='sm' color='success' onClick={() => manageRequest(value._id, true)}>Accept &nbsp;<CheckCircle sx={{ fontSize: '18px' }} /></Button>
                            </Stack>
                    )}
                </Grid>
            </Grid>
        )
    }

    const InputText = (e) => {
        const val = e.target.value;
        setSrch(val);
        const filteredData = data.message.filter(item =>
            item.name.toLowerCase().includes(val.toLowerCase())
        );
        setCredentials(filteredData);
    }

    return (
        <Box mt={2}>
            {/* Search bar */}
            <Stack marginBottom='10px' direction='row' justifyContent='space-between' alignItems='center'>
                {showRoute((specificpermCheck(pCredentials, ['Add Login Credentials'])), <Button variant='contained' color='success' endIcon={<AddTwoToneIcon />} onClick={openAdd}>Add</Button>, <Box></Box>)}
                <Stack direction='row' alignItems='center' spacing={2}>
                    {
                        credReview != 0 ? (showRequest
                            ? <Button variant='outlined' sx={ButtonRedStyle} onClick={closeRequest}>Cancel</Button>
                            : <Button variant='outlined' sx={ButtonStyle} onClick={viewRequest}>View Request(s)
                                {((credReview != null && credReview != 0)) ? <Box position='absolute' width='13px' height='13px' bgcolor='#657EBF' top='-4px' borderRadius='50%' right='-5px'></Box> : <></>}
                            </Button>
                        ) : <></>
                    }
                    <TextField
                        value={searched}
                        onChange={(e) => InputText(e)}
                        placeholder='Search Company'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            // endAdornment: (
                            //     searched!='' ? <InputAdornment position="end">
                            //         <ClearIcon color='error' />
                            //     </InputAdornment>
                            //         : <></>
                            // )
                        }}
                        sx={InputHeight}>
                    </TextField>
                </Stack>
            </Stack>

            {<CredView hdr='hdr' logo='' value={labels} keey='0' />}
            {
                credentials.length > 0
                    ? credentials.slice(8*(dataPage),8*(dataPage+1)).map((vaal, key) => {
                        return (
                            <CredView logo='' value={vaal} keey={key + 1} />
                        )
                    })
                    : <NodataFound msg='No Credentials found!' height='40vh' />
            }
            <Stack direction='row' justifyContent='center'>
            <Pagination
                size='small'
                sx={{ mt: '20px', mb: '10px' }}
                count={Math.ceil(credentials.length / 8)}
                page={dataPage + 1}
                onChange={onDataPageChange}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                    />
                )}
            />
            </Stack>

            {/* Dialog Box to add/edit credentials */}
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='sm'>
                <DialogTitle borderBottom='solid 1px lightgray'><Typography variant='h5' fontWeight='bold' fontFamily='Segoe UI'>Add new credential</Typography></DialogTitle>
                <DialogContent>
                    <CredentialForm addData={addData} setAddData={setAddData} editForm={editForm} approvers={approvers} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="error" sx={{ position: 'absolute', top: '10px', right: '5px' }}>
                        <Cancel />
                    </Button>
                    <LoadingButton endIcon={<CheckCircleTwoToneIcon />} variant="contained" loading={addForm} onClick={submitAddForm} color="primary">
                        {editForm ? 'Update' : 'Create'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
