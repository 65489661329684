import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { Token } from '../../constants'
import { SingleTable } from '../../features/components/Table/SingleTable'
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, LinearProgress, TextField, InputLabel, FormControl, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormikForm } from './FormikForm';
import Notify from '../../features/helpers/notifyConfig';
import { GraphInfo } from './GraphInfo';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export const addPoc = {
    "poc_name": "",
    "contact_no": "",
    "mail_id": "",
    "type": ""
};

export const Contracts = () => {
    const [accounts, setAccounts] = useState();
    // const titles = ['Account ID', 'Account Name', 'Action'];
    const titles = ['Account Name', 'Status', 'Products','Revenue','Contract Renewal Date', 'Action'];
    const [currentSales, setcurrentSales] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editAccount, setEditAccount] = useState("");
    const [graphData, setGraphData] = useState("");
    const [total, setTotal] = useState(null);

    const [data, setData] = useState({
        "company_name": "",
        "company_address": "",
        "status": "active",
        "point_of_contact": [addPoc]
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        // setLoading(true);
        setEditAccount("");
        setData({
            "company_name": "",
            "company_address": "",
            "status": "active",
            "point_of_contact": [addPoc]
        });
    };

    useEffect(() => {
        if (editAccount != "") {
            setOpen(true);
            setData(editAccount);
        }
    }, [editAccount])

    const submitAddForm = (type) => {
        let hasWarning = false;

        if (data.company_name === "") {
            Notify.warning("Company Name field cannot be empty!");
            hasWarning = true;
        }
        else if (data.company_address === "") {
            Notify.warning("Company Address field cannot be empty!");
            hasWarning = true;
        }
        else {
            data.point_of_contact.map((val) => {
                if (val.poc_name != "" && val.contact_no != "" && val.contact_no.length != 10){
                    Notify.warning("Contact No. should be of 10 numbers only!");
                    hasWarning = true;
                }
            })

            if (!hasWarning) {
                // 1. Adding New Account in Contract Signing
                const route = type == "add" ? "/dashboard/contract/addaccount" : "/dashboard/contract/editaccount";
                const addAccount = async () => {
                    await axios.post(route,
                        JSON.stringify(data),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                withCredentials: true,
                                mode: 'no-cors',
                                'Authorization': `Bearer ` + localStorage.getItem('access')
                            }
                        }).then((response) => {
                            console.log(response);
                            setOpen(false);
                            setLoading(prev => !prev);
                            setData({
                                "company_name": "",
                                "company_address": "",
                                "status": "active",
                                "point_of_contact": [addPoc]
                            });
                            type == "add" ? Notify.success("Account Added Successfully!") : Notify.success("Account Changes has been Saved!")
                        }).catch((error) => {
                            console.log(error.response);
                            Notify.error("Error Occurred. Try Again!");
                        })
                }
                addAccount();
            }
        }
    }

    // 2. Get All Account and their product list and details
    const getContractDetails = async () => {
        await axios.get("/dashboard/contract/all",
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ` + Token.Test,
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response);
            setAccounts(response?.data);
            let sum =0;
            let daata = response.data;
            daata.map((val) => {
                val.products.map((product) => {
                    sum += Number(product.price);
                })
            })
            setTotal(sum.toLocaleString());
        }).catch((error) => {
            console.log(error.response)
        });
    }

     // 3. Get Graph Details
     const getGraphDetails = async () => {
        await axios.get("/dashboard/contract/all/graph",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response);
            setGraphData(response?.data?.graph_data);
        }).catch((error) => {
            console.log(error.response)
        });
    }

    useEffect(() => {
        getContractDetails();
        getGraphDetails();
    }, [loading])

    return (
        <Box px={3} py={2}>
            {
                accounts
                    ?
                    <>
                    <Grid container spacing={4} mb={2.5}>
                        <Grid item xs={3}>
                            <Paper sx={{ padding: "13px" }} elevation={2}>
                                <Stack direction="row" alignItems="center">
                                    <AttachMoneyIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                    <Box px={2}>
                                        <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Total Revenue</Typography>
                                        <Typography variant='subtitle2'>{total?total:"Calculating..."}</Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Stack gap={2}>
                        <Button onClick={handleClickOpen} variant='contained' size='small'>Add Account&nbsp;&nbsp;<AddIcon /></Button>
                        <SingleTable setEditAccount={setEditAccount} titles={titles} accountInfo={accounts} account={true} setLoading={setLoading} />
                        {/* <GraphInfo data={graphData}/> */}
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="edit-apartment"
                            fullWidth
                            maxWidth='sm'
                        >
                            <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Account</DialogTitle>
                            <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                                <FormikForm data={data} setData={setData} />
                            </DialogContent>
                            <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                                <Button onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>
                                {editAccount == "" ?
                                    <Button variant='contained' onClick={() => submitAddForm("add")} color="primary">Add</Button> :
                                    <Button variant='contained' onClick={() => submitAddForm("edit")} color="primary">Save Changes</Button>
                                }
                            </DialogActions>
                        </Dialog>
                    </Stack>
                    </>
                    :
                    <Stack justifyContent="center" alignItems="center" height="80vh">
                        <Box pb={2}>Wait while we gather required information...</Box>
                        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}
