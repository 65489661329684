import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { addPoc } from './Contracts';

// ... Other imports

export const FormikForm = ({ data, setData, type = "" }) => {
  const handleAddInputData = (e) => {
    const { value, id } = e.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const addContactCard = () => {
    const updatedPoc = [...data.point_of_contact];
    updatedPoc.unshift({
      "poc_name": "",
      "contact_no": "",
      "mail_id": "",
      "type": ""
    });
    setData((prevState) => ({
      ...prevState,
      point_of_contact: updatedPoc,
    }));
  };

  const addCertificateCard = () => {
    const updatedPoc = [...data.certificates_details];
    updatedPoc.unshift({
      "certificate_name": "",
      "expiry_date": "",
    });
    setData((prevState) => ({
      ...prevState,
      certificates_details: updatedPoc,
    }));
  }

  const handlePOC = (e, key, field) => {
    const { value } = e.target;
    const updatedPoc = [...data.point_of_contact];
    updatedPoc[key][field] = value;
    setData((prevState) => ({
      ...prevState,
      point_of_contact: updatedPoc,
    }));
  };

  const handelCert = (e, key, field) => {
    const { value } = e.target;
    const updatedPoc = [...data.certificates_details];
    updatedPoc[key][field] = value;
    setData((prevState) => ({
      ...prevState,
      certificates_details: updatedPoc,
    }));
  };

  return (
    type == "" ?
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={data.company_name}
            id="company_name"
            label="Company Name"
            fullWidth
            onChange={handleAddInputData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={data.company_address}
            id="company_address"
            label="Company Address"
            fullWidth
            onChange={handleAddInputData}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" mt={3} textAlign="left">
            Point Of Contacts &nbsp;&nbsp;
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#C7C8CC",
                "&:hover": {
                  backgroundColor: "#B4B4B8",
                },
              }}
              onClick={addContactCard}
            >
              <AddIcon fontSize="7px" />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {data.point_of_contact.map((val, index) => (
            <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={val.poc_name ?? ""}
                    id={`poc_name_${index}`}
                    label="Name of Contact"
                    fullWidth
                    onChange={(e) => handlePOC(e, index, "poc_name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={val.contact_no ?? ""}
                    id={`contact_no_${index}`}
                    label="Contact Number"
                    type='number'
                    fullWidth
                    onChange={(e) => handlePOC(e, index, "contact_no")}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={val.mail_id ?? ""}
                    id={`mail_id_${index}`}
                    label="Mail ID"
                    fullWidth
                    onChange={(e) => handlePOC(e, index, "mail_id")}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id={`demo-simple-select-label_${index}`}>Type</InputLabel>
                    <Select
                      sx={{ height: '46px' }}
                      labelId={`demo-simple-select-label_${index}`}
                      id={`type_${index}`}
                      value={val.type ?? ""}
                      label="Type"
                      onChange={(e) => handlePOC(e, index, "type")}
                    >
                      <MenuItem value="">Select One</MenuItem>
                      <MenuItem value="primary">Primary</MenuItem>
                      <MenuItem value="corporate">Corporate</MenuItem>
                      <MenuItem value="technical">Technical</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </Paper>
          ))}
        </Grid>
      </Grid> :


      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={data.product_name}
            id="product_name"
            label="Product Name"
            fullWidth
            onChange={handleAddInputData}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            type='number'
            value={data.duration}
            id="duration"
            label="Duration (in months)"
            fullWidth
            onChange={handleAddInputData}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            // required
            type='date'
            value={data.start_date}
            id="start_date"
            label="Start Date"
            fullWidth
            onChange={handleAddInputData}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            // required
            type='date'
            value={data.end_date}
            id="end_date"
            label="End Date"
            fullWidth
            onChange={handleAddInputData}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={data.price}
            type='number'
            id="price"
            label="Price"
            fullWidth
            onChange={handleAddInputData}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={data.quantity}
            type='number'
            id="quantity"
            label="Quantity"
            fullWidth
            onChange={handleAddInputData}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography fontWeight="600" mt={3} textAlign="left">
            Add Certificates &nbsp;&nbsp;
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#C7C8CC",
                "&:hover": {
                  backgroundColor: "#B4B4B8",
                },
              }}
              onClick={addCertificateCard}
            >
              <AddIcon fontSize="7px" />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {data.certificates_details.map((val, index) => (
            <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={val.certificate_name ?? ""}
                    id={`certificate_name_${index}`}
                    label="Certificate Name"
                    fullWidth
                    onChange={(e) => handelCert(e, index, "certificate_name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type='date'
                    value={val.expiry_date??""}
                    id={`expiry_date_${index}`}
                    label="Expiry Date"
                    fullWidth
                    onChange={(e) => handelCert(e, index, "expiry_date")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

              </Grid>
            </Paper>
          ))}
        </Grid>

      </Grid>
  );
};

