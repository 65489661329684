import { Box, Typography, Stack, Paper, Link, Grid, Dialog, Button, DialogContent, DialogTitle, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Sharepoint } from './Icons/Sharepoint'
import { Oreilly } from './Icons/Oreilly'
import { MyDashboard } from './Icons/MyDashboard'
import { Credential } from './Icons/Credential'
import { NavLink } from 'react-router-dom'
import { Cancel } from '@mui/icons-material'
import axios from '../api/axios'
import { CredentialsView } from './ui/CredentialsView'
import { Coursera } from './Icons/Coursera'

const launchers = [
    {
        linkText: "Sharepoint",
        icons: <Sharepoint width={38} />,
        link: "https://encryptionconsultingcom.sharepoint.com/sites/ec-sharepoint/Shared%20Documents/Forms/AllItems.aspx"
    },
    {
        linkText: "Oreilly",
        icons: <Oreilly width={38} />,
        link: "https://www.oreilly.com/member/login/"
    },
    {
        linkText: "Coursera",
        icons: <Coursera width={38} />,
        link: "https://www.coursera.org/?authMode=login"
    },
    {
        linkText: "Credentials",
        icons: <Credential width={40} />,
        link: "https://encryptionconsultingcom.sharepoint.com/:x:/r/sites/ec-sharepoint/_layouts/15/Doc.aspx?sourcedoc=%7BEC2BFE04-581B-40D7-A97A-E02D2C0F4DB4%7D&file=Logins%20for%20Company.xlsx&action=default&mobileredirect=true"
    },
]

export const LaunchPad = ({ credReview,isCredReview }) => {
    const [open, setOpen] = useState(false);
    const [credData, setCredData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getCreds = async () => {
        await axios.get("/dashboard/loginCredentials",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setCredData(response?.data);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    const openCreds = () => {
        setOpen(true);
        getCreds();
    }

    useEffect(() => {
        if (loading) {
            getCreds();
            setLoading(false);
        }
    }, [loading])

    const onClose = () => {
        setOpen(false);
    }

    const Cubes = ({ icon, link }) => {
        return (
            <Box
                sx={{
                    transition: 'ease-in-out 0.2s',
                    position: 'relative',
                    cursor: 'pointer',
                    ':hover': {
                        transform: 'scale(1.05)'
                    }
                }}
                width={55}>
                {((credReview!=null && credReview!=0) && link=='')?<Box position='absolute' width='13px' height='13px' bgcolor='#657EBF' top='-4px' borderRadius='50%' right='-5px'></Box>:<></>}
                <Paper elevation={4}>
                    {
                        link != ''
                            ? <NavLink target='_blank' to={link}>
                                <Box bgcolor='primary.white' width={55} height={50} borderRadius={1}>
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        {icon}
                                    </Stack>
                                </Box>
                            </NavLink>
                            :
                            <Box onClick={openCreds} bgcolor='primary.white' width={55} height={50} borderRadius={1}>
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {icon}
                                </Stack>
                            </Box>
                    }
                </Paper>

            </Box>
        )
    }

    return (
        <Box width={'100%'} bgcolor="#F5FAFF" sx={{ border: "solid 1px #E0E0E0", display: "inline-block", borderRadius: "5px" }}>
            <Box px={2} py={1} borderBottom={'solid 1px #E0E0E0'}>
                <Typography fontWeight="600" color="#1B2C58">Launch Pad</Typography>
            </Box>
            <Grid container direction="row">
                {launchers.map(({ linkText, url, icons, link }, key) => (
                    <Grid item xs={12} md={6} lg={4} xl={3} px={2} py={1}>
                        <Stack alignItems="center">
                            <Cubes key={key} icon={icons} link={linkText == 'Credentials' ? '' : link} />
                            <Typography textAlign="center" pt={1} variant='subtitle2'>{linkText}</Typography>
                        </Stack>
                    </Grid>
                )
                )}
            </Grid>

            {/* 1. Dialog box to show Credentials Window */}
            <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={onClose}>
                <DialogTitle><Typography variant='h5' fontWeight='bold' fontFamily='Segoe UI'>Credential Details</Typography></DialogTitle>
                <DialogContent sx={{ minHeight: '70vh' }}>
                    {credData
                        ? <CredentialsView data={credData} setLoading={setLoading} isCredReview={isCredReview} credReview={credReview} />
                        : <Stack justifyContent="center" alignItems="center" height="70vh">
                            <Box pb={2}>Please wait while credentials are loading...</Box>
                            <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="inherit" />
                            </Stack>
                        </Stack>}
                </DialogContent>

                <Button sx={{ position: "absolute", top: 15, right: 10 }} onClick={onClose} color="error">
                    <Cancel sx={{ fontSize: "1.8rem" }} />
                </Button>
            </Dialog>
        </Box>
    )
}
