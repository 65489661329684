import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isLead } from '../../components/auth/userAuth'
import { NodataFound } from '../NodataFound'
import axios from '../../api/axios'
import { Cancel } from '@mui/icons-material'
import { GoalForm } from './Forms/GoalForm'
import Notify from '../../features/helpers/notifyConfig'
import { currentUser, permAllowed, showRoute, specificpermCheck } from "../../Router"
import UserIcon from '@mui/material/Avatar';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { GoalView } from './Forms/GoalView'
import { advancedDate, postData } from '../Events'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const SixMonthsPriorComponent = (dateString) => {
    const date = new Date(dateString);
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 6);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = newDate.toLocaleDateString('en-GB', options);
    return formattedDate;
}

export const UserGoals = () => {
    const [data, setData] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const [empGoal, setEmpGoal] = useState(null);
    const [goals, openGoals] = useState(false);
    const [finData, setFinData] = useState({
        "emp_id": "",
        "goals": []
    });
    const { pAppraisal, user_role_string_id, user_id } = currentUser();
    const [alignment, setAlignment] = useState('goal');

    const handleClose = () => {
        setOpen(false);
        openGoals(false);
    };

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const openGoalWindow = () => {
        setOpen(true);
    }

    const EmployeeGoalWindow = (value) => {
        setEmpGoal(value);
        openGoals(true);
    }

    const submitAddForm = async () => {
        await axios.post(`/appraisal/add-goals`,
            JSON.stringify(finData),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                Notify.success("Goal Added Successfully");
                setFinData({
                    "emp_id": "",
                    "goals": []
                })
                setLoad(true);
                handleClose();
            }).catch((error) => {
                // console.log(error.response);
                Notify.error("Goal Can't Be Added. Try Again!")
            })
    }

    const SeeGoalStyle = () => {
        return {
            cursor: 'pointer',
            color: 'grey',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                color: 'primary.main',
                transform: 'translateX(5px)',
            }
        }
    }

    const Wrapper = ({ value, addtn }) => {
        return (
            <Grid lg={3} md={4} item>
                <Paper sx={{ borderRadius: '10px', overflow: 'hidden' }} elevation={2}>
                    <Box>
                        <Stack bgcolor={value.emp_id == user_id ? '#880e4f' : '#00695c'} direction='row' justifyContent='center' py={2}>
                            <UserIcon variant='rounded' sx={{ width: '60px', height: '60px', border: 'solid 2px white', transform: 'translateY(40px)' }} />
                        </Stack>
                        <Box px={2} py={4} textAlign='center'>
                            {empList
                                ? Object.keys(empList).map((key, val) => {
                                    if (empList[key].emp_uuid == value.emp_id) {
                                        return (
                                            <Typography variant='h6' fontFamily='Segoe UI' fontWeight='700'>{value.emp_id == user_id ? "You" : empList[key].employee_name}</Typography>
                                        )
                                    };
                                })
                                : ""}
                            <Stack onClick={() => EmployeeGoalWindow(value)} direction='row' justifyContent='center' alignItems='center' className='GoalAnime' sx={SeeGoalStyle}>
                                <Typography fontWeight='500' variant='subtitle1' fontFamily='Segoe UI'>{value.goals.length} goals added&nbsp;</Typography>
                                <KeyboardDoubleArrowRightIcon fontSize='sm' />
                            </Stack>

                            <Box mt={0.4}>
                                <Typography variant='subtitle2' fontFamily='Segoe UI' fontWeight='400'><i>next evaluation<ArrowRightIcon sx={{ my: '-7px', transform: 'rotateZ(5deg)' }} />{SixMonthsPriorComponent(advancedDate(postData(value.created_at)))}</i></Typography>
                                {/* {value.emp_id == user_id ?<Typography variant='subtitle2' fontFamily='Segoe UI' fontWeight='400'>Reporting to {
                            Object.keys(empList).map((key, val) => {
                                return (empList[key].emp_uuid == value.reporting_to.mgr2) ? empList[key].employee_name : ""
                            })
                            }</Typography>:<></>} */}
                            </Box>

                        </Box>
                    </Box>
                </Paper>
            </Grid>
        )
    }

    const getGoals = async () => {
        await axios.get("/appraisal/get-goal",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                // console.log(response.data.message ?? response.data);
                setData(response.data.message ?? response.data);
            }).catch((error) => {
                // console.log(error.response)
            })
    }

    const getEmployees = async () => {
        await axios.get("/blog-tracking/get-all-employees",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }).then((response) => {
                // console.log(response);
                setEmpList(response?.data?.message);
            }).catch((error) => {
                // console.log(error.response)
            })
    }

    useEffect(() => {
        getEmployees();
        getGoals();
    }, [])

    useEffect(() => {
        if (load) {
            getGoals();
            setLoad(false);
        }
    }, [load])

    return (
        <Box py={2} px={3}>
            <Stack justifyContent='space-between' direction='row'>
                <Typography variant='h5' fontWeight={600}>{isLead("Future Goals of your Team", "Your Future Goals")}</Typography>
                {showRoute((specificpermCheck(pAppraisal, ['Add Goals'])), <Button onClick={openGoalWindow} variant='contained'>Create</Button>, <></>)}
            </Stack>

            <Box mt={3}>
                {
                    data
                        ? (data == "No documents found"
                            ? <NodataFound msg="No goals set. Discuss with your lead first !" />
                            : (
                                <Grid container spacing={5}>
                                    {data.your_entries.map((key, ind) => {
                                        return (
                                            <Wrapper addtn={ind} value={key} />
                                        )
                                    })}

                                    {data.managed_entries.map((key, ind) => {
                                        return (
                                            <Wrapper addtn={ind} value={key} />
                                        )
                                    })}
                                </Grid>

                                // data.your_entries.map((key, ind) => {
                                //     return (<Wrapper addtn={ind} value={key} />)
                                // })
                            )
                        )
                        : <Stack justifyContent="center" alignItems="center" height="70vh">
                            <Box pb={2}>Wait while we gather required information...</Box>
                            <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="inherit" />
                            </Stack>
                        </Stack>
                }
            </Box>

            {/* Set Goals box for team lead only */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                {/* <DialogTitle id="edit-apartment"> */}
                <Box sx={{margin: '10px 10px 0px 10px'}}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        size='small'
                        value={alignment}
                        onChange={handleChange}
                    >
                        <ToggleButton sx={{borderRadius:'5px 0px 0px 5px !important', px:'18px'}} value="goal">Goal</ToggleButton>
                        <ToggleButton sx={{borderRadius:'0px 5px 5px 0px !important', px:'18px'}} value="media">Contribution</ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box sx={{ background: alignment=='goal'?'url("./assets/goal-set.jpg") gray':'url("./assets/media.jpg") gray', margin: '10px', borderRadius: '10px', boxShadow: 'inset 1px 1px 25px 10px #d3d3d387', backgroundSize: 'cover', backgroundPosition: 'center', padding: '1.5rem 1rem 1.5rem' }}>
                    <Typography variant='h5' fontFamily='Segoe UI' color='white' fontWeight='700'>{alignment=='goal'?"Set New Goal":"Add Your Contribution"}</Typography>
                    <Typography color='white' fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400' lineHeight={1.2}>Select among different content categories and select your respective </Typography>
                </Box>
                {/* </DialogTitle> */}
                <DialogContent>
                    <GoalForm empList={empList} finData={finData} setFinData={setFinData} />
                </DialogContent>
                <DialogActions>
                    <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="error">
                        <Cancel sx={{ fontSize: "1.6rem" }} />
                    </Button>
                    <Button variant='contained' onClick={submitAddForm} sx={{ px: 6 }} color="primary">
                        Set
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Open Individual's goal window */}
            {(empList && empGoal)
                ? <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={goals}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                >
                    <DialogTitle id="edit-apartment" sx={{ bgcolor: '#e9e9e9' }}>
                        <Typography variant='h5' fontFamily='Segoe UI' fontWeight='700' mb={0.3}>Goal Details of&nbsp;
                            {Object.keys(empList).map((key, val) => {
                                return (empList[key].emp_uuid == empGoal.emp_id) ? empList[key].employee_name : ""
                            })}
                        </Typography>
                        {/* <Typography fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400'>Provide status to this particular goal based on employee's progress on respective goal!</Typography> */}
                    </DialogTitle>
                    <DialogContent sx={{ bgcolor: '#e9e9e9', borderTop: 'solid 1px lightgrey' }}>
                        {empGoal
                            ? <GoalView load={load} setLoad={setLoad} empList={empList} value={empGoal} user_id={user_id} />
                            : <></>
                        }
                    </DialogContent>
                    <Button sx={{ position: "absolute", top: 14, right: 2 }} onClick={handleClose} color="error">
                        <Cancel sx={{ fontSize: "1.6rem" }} />
                    </Button>
                </Dialog>
                :
                <></>
            }
        </Box>
    )
}
