import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box, Tabs, Tab, Stack, Skeleton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Notify from '../../../features/helpers/notifyConfig';
import axios from '../../../api/axios';
import { styled } from '@mui/material/styles';
import { capitalizeFirstLetter, ExpenseRequests } from './ExpenseRequests';

export function ManageExpense() {
  const [value, setValue] = useState("pending");
  const [infodata, setInfodata] = useState(null);
  const [reqdata, setReqdata] = useState(null);
  const [reload, setReload] = useState(false);

  //1. get projects and manager info regarding expense
  const getExpInfo = async () => {
    await axios.get("/expense-report",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      // console.log(response);
      setInfodata(response?.data?.expenseReportDependancies);
    }).catch((error) => {
      Notify.error(error.response ? (error?.response?.data?.error) : "Request Timed Out");
    });
  }

  //2. get projects and manager info regarding expense
  const getreqInfo = async () => {
    await axios.get("/expense-report/pending",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      setReqdata(response?.data?.expenseReportPendingClaims);
    }).catch((error) => {
      Notify.error(error.response ? (error?.response?.data?.error) : "Request Timed Out");
    });
  }

  useEffect(() => {
    getExpInfo();
    getreqInfo();
  }, [reload])

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <Box sx={{ width: '100%' }} py={3} px={3}>

      <Stack alignItems='center' justifyContent='space-between' direction='row' >
        <Typography ml={1} variant='h5' fontFamily='Segoe UI'>{capitalizeFirstLetter(value)} Claims</Typography>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Status"
            onChange={(e) => handleChange(e.target.value)}
          >
            <MenuItem value="pending"><Typography variant='subtitle2'>Pending</Typography></MenuItem>
            <MenuItem value="approved"><Typography variant='subtitle2'>Approved</Typography></MenuItem>
            <MenuItem value="rejected"><Typography variant='subtitle2'>Rejected</Typography></MenuItem>
          </Select>
        </FormControl>
      </Stack>
      
      <div className='divider_line'></div>

      {(infodata && reqdata)
        ? <ExpenseRequests setReload={setReload} infodata={infodata} reqdata={reqdata.filter((val)=>val.status==value)} value={value} />
        : <Stack gap={2} width="100%">
          <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
          <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
      }
    </Box>
  );
}