import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, LinearProgress, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { Progressor } from '../../../components/ui/Progressor'
import { advancedDate, postData } from '../../Events'
import UserIcon from '@mui/material/Avatar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';
import { EvaluateForm } from './EvaluateForm'
import { ArrowForward, Cancel, Delete } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error';
import { MileStoneForm } from './MileStoneForm';
import Notify from '../../../features/helpers/notifyConfig';
import axios from '../../../api/axios';
import { NodataFound } from '../../NodataFound';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';

const ButtonStyle = {
    '&.MuiButtonBase-root': {
        height: '30px !important',
        padding: '0px 15px',
        // background: '#198f51'
    }
}

const tinyButton = {
    fontSize: '10px',
    '&.MuiButtonBase-root': {
        height: '22px !important',
        padding: '0px 5px',
        // background: '#198f51'
    }
}

export const TitleandDesc = (title, desc) => {
    return (
        <Box>
            <Typography color='primary.main' fontFamily='Segoe UI' variant='subtitle2' fontWeight='700'>{title}</Typography>
            <Typography fontFamily='Segoe UI' mt='-2px' variant='body2' fontWeight='500'>{desc}</Typography>
        </Box>
    )
}

export const GoalView = ({ value, empList, user_id, setLoad, load }) => {
    const [evaluateData, setEvaluateData] = useState();
    const [evaluate, setEvaluation] = useState(false);
    const [fevaluate, setFEvaluation] = useState(null);
    const [milestn, setMilstn] = useState(null);
    const [goalie, setGoalie] = useState({
        "goal_id": "",
        "key_milestone": [
        ]
    });
    const [viewmilestn, setViewMilstn] = useState(null);

    const EvaluationLink = (uuid, goal, goal_id) => {
        setEvaluation(true);
        setEvaluateData({
            uuid: uuid,
            goal: goal,
            goal_id: goal_id
        });
    }

    const FinalEvaluationLink = (goal, goal_id) => {
        setFEvaluation({ goal_id, goal });
    }

    const SetMileStones = (goal_id) => {
        setMilstn(goal_id);
    }

    const handleClose = () => {
        setEvaluation(false);
        setFEvaluation(null);
        setMilstn(null);
        setViewMilstn(null);
    };

    const viewMilestones = (index) => {
        setViewMilstn(index);
    }

    const deleteGoal = (id) => {
        const deleteNow = async () => {
            await axios.delete(`/appraisal/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "goal_id": id
                    }
                }).then((response) => {
                    Notify.success('Goal Deleted Successfully!');
                    // setotherAction(false);
                    handleClose();
                }).catch((error) => {
                    Notify.error('Some Error Occurred. Try again');
                })
        }
        deleteNow();
    }

    const submitMilestones = () => {
        if (goalie.key_milestone.length > 0) {
            const submitMSForm = async () => {
                await axios.post(`/appraisal/add-key-milestone`,
                    JSON.stringify(goalie),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            withCredentials: true,
                            mode: 'no-cors',
                            'Authorization': `Bearer ` + localStorage.getItem('access')
                        }
                    }).then((response) => {
                        Notify.success("Milestone/s Added Successfully");
                        setGoalie({
                            "goal_id": "",
                            "key_milestone": [
                            ]
                        })
                        setLoad(true);
                        handleClose();
                    }).catch((error) => {
                        // console.log(error.response);
                        Notify.error("Milestones Can't Be Added. Try Again!")
                    })
            }
            submitMSForm();
        }
        else {
            Notify.warning("Atleast 1 milestone has to be submitted");
        }
    }

    useEffect(() => {

    }, [load])

    return (
        <Box mt={3}>
            {value.goals.map((ind, key) => {
                return (
                    <Box borderLeft='solid 4px' borderColor='primary.main' bgcolor='white' borderRadius='5px' mb={2}>
                        {Object.keys(empList).map((key, val) => {
                            if (empList[key].emp_uuid == value.emp_id)
                                return ((value.emp_id == user_id) && (ind.key_milestone.length == 0)
                                    ? <Box px={1} pt={1}>
                                        <Stack sx={{
                                            cursor: 'pointer', '&:hover': {
                                                backgroundColor: '#f5c5c5'
                                            }
                                        }} bgcolor='#FFDADA' border='solid 1px #f04949' borderRadius='5px' pl={1} onClick={() => SetMileStones(ind.goal_id)} direction='row' alignItems='center'>
                                            <ErrorIcon color='error' sx={{ transform: 'translateY(0.5px)', fontSize: '15px' }} />
                                            <Typography fontSize='13px' ml={0.3} fontWeight='500' p={0.3} fontFamily='Segoe UI'>Milstones are not set. <span style={{ fontWeight: '600' }} >Click to set now</span></Typography>
                                            <ArrowForward sx={{ transform: 'translateY(1.5px)', fontSize: '13px' }} />
                                        </Stack>
                                    </Box>
                                    : <></>)
                        }
                        )}

                        <Grid container direction='column' py={1} px={2} >
                            <Grid item>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={11}>
                                        <Typography fontFamily='Segoe UI' variant='h6' color={'primary.main'} fontWeight={700} key={key}>{ind.goal}</Typography>
                                    </Grid>
                                    {value.emp_id != user_id
                                        ? <Grid item>
                                            <IconButton sx={{ marginTop: '5px', marginRight: '2px' }}><CreateTwoToneIcon color='primary' sx={{ fontSize: '20px' }} /></IconButton>
                                            <IconButton sx={{ marginLeft: 'auto', marginTop: '5px' }} onClick={() => deleteGoal(ind.goal_id)}> <DeleteOutlineTwoToneIcon color='error' sx={{ fontSize: '20px' }} /> </IconButton>
                                        </Grid>
                                        : <></>
                                    }
                                </Grid>

                                <Stack direction='row' spacing={5} mt={1}>
                                    {
                                        TitleandDesc('Status', ind.status ?? 'Not Updated')
                                    }
                                    {
                                        TitleandDesc('Manager Remarks', (ind.points_mgr == 0) ? 'No Remark' : ind.points_mgr)
                                    }
                                    {
                                        TitleandDesc('Employee Remarks', (ind.points_emp == 0) ? 'No Remark' : ind.points_emp)
                                    }
                                </Stack>
                            </Grid>

                            <Grid item>
                                <Progressor label={ind.progress} />
                            </Grid>

                            <Grid item mt={2}>
                                <Stack direction='row' justifyContent='space-between'>
                                    <Tooltip title={ind.key_milestone ? '' : 'Milestones are not set'}>
                                        <Button sx={ButtonStyle} size='sm' variant='outlined' onClick={() => viewMilestones(key)} color='success' disabled={ind.key_milestone.length != 0 ? false : true}>View Milestones</Button>
                                    </Tooltip>

                                    {Object.keys(empList).map((key, val) => {
                                        if ((empList[key].emp_uuid == value.emp_id) && (value.emp_id != user_id)) {
                                            return (<Stack spacing={3} direction='row'>
                                                <Button sx={ButtonStyle} onClick={() => EvaluationLink(value.uuid, ind.goal, ind.goal_id)} size='sm' variant='outlined'>6-month Evaluation</Button>
                                                <Button sx={ButtonStyle} size='sm' variant='contained' onClick={() => FinalEvaluationLink(ind.goal, ind.goal_id)}>Final Evaluation</Button>
                                            </Stack>)
                                        }
                                    })}

                                </Stack>
                            </Grid>

                        </Grid>
                    </Box>
                )
            })}

            {/* 6-month Evaluation by team lead */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={evaluate}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogTitle id="edit-apartment" sx={{ bgcolor: '#e9e9e9' }}>
                    <Typography variant='h5' fontFamily='Segoe UI' fontWeight='700' mb={0.7}>6-month Evaluation</Typography>
                    <Typography fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400'>Provide status to this particular goal based on employee's progress on respective goal!</Typography>
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#e9e9e9', borderTop: 'solid 1px lightgrey' }}>
                    <EvaluateForm data={evaluateData} setEvaluation={setEvaluation} />
                </DialogContent>
                <Button sx={{ position: "absolute", top: 10, right: 2 }} onClick={handleClose} color="error">
                    <Cancel sx={{ fontSize: "1.6rem" }} />
                </Button>
            </Dialog>

            {/* Final Evaluation by team lead */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={fevaluate ? true : false}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogTitle id="edit-apartment" sx={{ bgcolor: '#e9e9e9' }}>
                    <Typography variant='h5' fontFamily='Segoe UI' fontWeight='700' mb={0.7}>Final Evaluation</Typography>
                    {/* <Typography fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400'>Provide status to this particular goal based on employee's progress on respective goal!</Typography> */}
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#e9e9e9', borderTop: 'solid 1px lightgrey' }}>
                    .   {fevaluate ? <EvaluateForm data={fevaluate} setEvaluation={setFEvaluation} type='final' /> : <></>}
                </DialogContent>
                <Button sx={{ position: "absolute", top: 10, right: 2 }} onClick={handleClose} color="error">
                    <Cancel sx={{ fontSize: "1.6rem" }} />
                </Button>
            </Dialog>

            {/* Set Milestones */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={milestn ? true : false}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogTitle id="edit-apartment" sx={{ bgcolor: '#e9e9e9' }}>
                    <Typography variant='h5' fontFamily='Segoe UI' fontWeight='700' mb={0.7}>Set Milestones for your goal</Typography>
                    <Typography fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400'>Milestones are important and help your lead to track down your progresss on the goal.</Typography>
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#e9e9e9', borderTop: 'solid 1px lightgrey' }}>
                    <MileStoneForm goal_id={milestn ? milestn : ""} goalie={goalie} setGoalie={setGoalie} />
                </DialogContent>
                <Button sx={{ position: "absolute", top: 10, right: 2 }} onClick={handleClose} color="error">
                    <Cancel sx={{ fontSize: "1.6rem" }} />
                </Button>

                <DialogActions sx={{ bgcolor: '#e9e9e9' }}>
                    <Button variant='contained' sx={{ px: '30px' }} onClick={submitMilestones}>Add</Button>
                </DialogActions>
            </Dialog>

            {/* View Milestones */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={(viewmilestn != null) ? true : false}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogTitle sx={{ bgcolor: '#e9e9e9', borderBottom: 'solid 1px lightgrey' }}>
                    <Typography color='primary.main' variant='h6'>Milestones</Typography>
                    <Typography variant='subtitle2'>Status of these milestones are changed by the respective employee only.</Typography>
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#e9e9e9' }}>
                    {
                        (viewmilestn != null)
                            ?
                            <>
                                <Grid mt={1} container justifyContent='center'>
                                    <Grid xs={10} item>
                                        <Typography variant='subtitle1' fontFamily='Segoe UI' fontWeight='600'>Label</Typography>
                                    </Grid>
                                    <Grid xs={2} item>
                                        <Typography variant='subtitle1' fontFamily='Segoe UI' fontWeight='600'>Completed</Typography>
                                    </Grid>
                                </Grid>
                                {value.goals[viewmilestn].key_milestone.map((vaal, key) => {
                                    return (
                                        <Grid container key={key} mt={1} spacing={1} alignItems='center'>
                                            <Grid xs={10} item>
                                                <Typography variant='subtitle1' fontFamily='Segoe UI' fontWeight='400'>{vaal}</Typography>
                                            </Grid>
                                            <Grid xs={2} item>
                                                {
                                                    value.emp_id == user_id
                                                        ? value.goals[viewmilestn].key_milestone.vaal ? <CheckCircleIcon fontSize='15px' sx={{ color: '#15892f' }} /> : <Button sx={tinyButton} variant='contained'>Complete</Button>
                                                        : value.goals[viewmilestn].key_milestone.vaal ? <CheckCircleIcon fontSize='15px' sx={{ color: '#15892f' }} /> : <Cancel fontSize='15px' color='error' />
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                }
                            </>
                            : <NodataFound msg='No milestones has been set' />
                    }
                </DialogContent>

                <DialogActions sx={{ bgcolor: '#e9e9e9', borderTop: 'solid 1px lightgrey' }}>
                    <Button onClick={handleClose} variant='contained' color="error">Close</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}
