import React, { useState } from 'react'
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { holidayApi } from '../api/holidayApi';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const isHoliday = (stat) => {
    if (stat == true) return <CheckIcon color='success' />
    return <ClearIcon color='error' />
}

const columns = [
    {
        field: 'holiday',
        headerName: 'Holiday/Disconnect Day',
        headerClassName: 'whiteMenuIcon',
        paddingLeft: '10px',
        width: 320,
        renderCell: (params) => <Box pl={2}>{params.row.holiday}</Box>
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 200,
        headerClassName: 'centerStyle',
        align: 'center',
        // renderCell: (params) => <strong>{params.row.date}</strong>
    },
    {
        field: 'bengaluru',
        headerName: 'Bengaluru',
        headerClassName: 'centerStyle',
        width: 100,
        align: 'center',
        renderCell: (params) => isHoliday(params.row.bengaluru)
    },
    {
        field: 'chennai',
        headerName: 'Chennai',
        width: 100,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.chennai)
    },
    {
        field: 'gurgaon',
        headerName: 'Gurgaon',
        width: 100,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.gurgaon)
    },
    {
        field: 'hyderabad',
        headerName: 'Hyderabad',
        width: 110,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.hyderabad)
    },
    {
        field: 'kolkata',
        headerName: 'Kolkata',
        width: 100,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.kolkata)
    },
    {
        field: 'mumbai',
        headerName: 'Mumbai & Pune',
        width: 150,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.mumbai)
    },
];

const columnsUSA = [
    {
        field: 'holiday',
        headerName: 'Holiday/Disconnect Day',
        headerClassName: 'whiteMenuIcon',
        paddingLeft: '10px',
        width: 320,
        renderCell: (params) => <Box pl={2}>{params.row.holiday}</Box>
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 200,
        headerClassName: 'centerStyle',
        align: 'center',
        // renderCell: (params) => <strong>{params.row.date}</strong>
    },
];

const rows = [
    { id: 1, holiday: "New Year's Day", date: 'Monday, January 1', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 2, holiday: "Makara Sankranti / Pongal", date: 'Monday, January 15', bengaluru: true, chennai: true, gurgaon: false, hyderabad: true, kolkata: false, mumbai: false },
    { id: 3, holiday: "Netaji Subhash Chandra Bose Jayanti", date: 'Tuesday, January 23', bengaluru: false, chennai: false, gurgaon: false, hyderabad: false, kolkata: true, mumbai: false },
    { id: 4, holiday: "Republic Day", date: 'Monday, Friday 26', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 5, holiday: "Holi", date: 'Monday, March 25', bengaluru: false, chennai: false, gurgaon: true, hyderabad: false, kolkata: false, mumbai: true },
    { id: 6, holiday: "Eid Ul Fitr", date: 'Thursday, April 11', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 7, holiday: "Nationwide General Parliament Election", date: 'April/May (TBD)', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 8, holiday: "May/Labor Day", date: 'Wednesday, May 1', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 9, holiday: "Independence Day", date: 'Thursday, August 15', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 10, holiday: "Gandhi Jayanti", date: 'Wednesday, October 2', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 11, holiday: "Diwali", date: 'Thursday, October 31', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 12, holiday: "Extended Diwali Day", date: 'Friday, November 1', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 13, holiday: "Christmas Day", date: 'Wednesday, December 25', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
];

const rowsUSA = [
    { id: 1, holiday: "New Year's Day", date: 'Saturday, January 1'},
    { id: 2, holiday: "Martin Luther King, Jr. Day", date: 'Monday, January 17'},
    { id: 3, holiday: "Washington's Birthday", date: 'Monday, February 21'},
    { id: 4, holiday: "Memorial Day", date: 'Monday, May 30'},
    { id: 5, holiday: "Juneteenth (observed)", date: 'Monday, June 20'},
    { id: 6, holiday: "Independence Day ", date: 'Monday, July 4'},
    { id: 7, holiday: "Labor Day", date: 'Monday, September 5 '},
    { id: 8, holiday: "Veterans Day", date: 'Friday, November 11'},
    { id: 9, holiday: "Thanksgiving Day", date: 'Thursday, November 22'},
    { id: 10, holiday: "Christmas Day", date: 'Sunday, December 25'},
];


export const HolidaysBox = () => {
    const [open, setOpen] = useState(false);
    const [openUSA, setOpenUSA] = useState(false);

    const showHoliday = () => {
        setOpen(true);
    }

    const showUSAHoliday = () => {
        setOpenUSA(true);
    }

    const onClose = () => {
        setOpen(false);
        setOpenUSA(false);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ background: '#F5FAFF', borderRadius: '5px' }}>
                <Box px={2} py={1} borderBottom={'solid 1px #E0E0E0'}>
                    <Typography fontWeight="600" color="#1B2C58">Calendar</Typography>
                </Box>

                <Stack justifyContent='center' alignItems='center'>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                        <DemoItem>
                            <DateCalendar
                                sx={{
                                    "& .MuiDayCalendar-weekContainer": {
                                        // margin: '8px 0'
                                    }
                                }}
                                defaultValue={dayjs(new Date())}
                                readOnly />
                        </DemoItem>
                    </DemoContainer>

                    <Stack direction='row' columnGap={2} mt={1}>
                        <Button onClick={showHoliday} sx={{ marginBottom: '12px' }} variant='contained'>Holidays | IN</Button>
                        <Button onClick={showUSAHoliday} sx={{ marginBottom: '12px' }} variant='contained'>Holidays | USA</Button>
                    </Stack>
                </Stack>
            </Box>

            <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth='lg' >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnSelector
                    hideFooter
                    disableColumnMenu
                    disableColumnSorting
                    style={{ backgroundColor: 'white' }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'primary.main'
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '15px',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            fontSize: '16px'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                        '& .MuiInputBase-input': {
                            height: '20px !important'
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                            boxShadow: 'none',
                            border: 'none'
                        },
                    }}
                />
                <DialogActions><Button variant='contained' color='error' onClick={onClose}>Cancel</Button></DialogActions>
            </Dialog>


            <Dialog onClose={onClose} open={openUSA}>
                <DataGrid
                    rows={rowsUSA}
                    columns={columnsUSA}
                    disableColumnSelector
                    hideFooter
                    disableColumnMenu
                    disableColumnSorting
                    style={{ backgroundColor: 'white' }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'primary.main'
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '15px',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            fontSize: '16px'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                        '& .MuiInputBase-input': {
                            height: '20px !important'
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                            boxShadow: 'none',
                            border: 'none'
                        },
                    }}
                />
                <DialogActions><Button variant='contained' color='error' onClick={onClose}>Cancel</Button></DialogActions>
            </Dialog>
        </LocalizationProvider>
    )
}
